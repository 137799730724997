<template>
  <div class="register">
    <el-card class="box-card">
      <h2>我们开始吧</h2>
      <h3>在下面的表格上注册</h3>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名：" prop="nickname">
          <el-input v-model="ruleForm.nickname" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="电子邮件：" prop="email">
          <el-input v-model="ruleForm.email" placeholder="电子邮件"></el-input>
        </el-form-item>
        <el-form-item label="微信号：" prop="weixin">
          <el-input v-model="ruleForm.weixin" placeholder="微信号"></el-input>
        </el-form-item>
        <el-form-item label="电话号码：" prop="mobile">
          <el-input v-model="ruleForm.mobile" placeholder="电话号码"></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="code">
          <el-input
            type="number"
            v-model="ruleForm.code"
            placeholder="请输入验证码"
          >
            <template slot="append">
              <span v-show="show" @click="getCode">获取验证码</span>
              <span v-show="!show" class="count">{{ count }} s</span></template
            ></el-input
          >
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input
            show-password
            v-model="ruleForm.password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码确认：" prop="password2">
          <el-input
            show-password
            v-model="ruleForm.password2"
            placeholder="再次输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="您的链接用户名：" prop="username">
          <el-input
            v-model="ruleForm.username"
            placeholder="请使用如tom2376，勿使用标点及其他符号。"
          ></el-input>
        </el-form-item>

        <div class="tipurl">
          生物链接是指为您个人生成的短域名，包含您所有愿意展示的个人链接集合。
        </div>
        <el-form-item>
          <el-checkbox v-model="checked"> </el-checkbox>
          <span class="tip">
            <span style="color: #ff0000">*</span>我已了解并同意
            <span @click="opendialog(true)">服务协议</span>
            和
            <span @click="opendialog(false)">隐私政策</span></span
          >
        </el-form-item>
        <el-form-item class="btn">
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            v-loading.fullscreen.lock="loading"
            >注册</el-button
          >
          <span class="login"
            >已有账号？<el-link @click="login" type="primary"
              >直接登入</el-link
            ></span
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      center
      :before-close="handleClose"
    >
      <div v-html="content"></div>
    </el-dialog>
  </div>
</template>

<script>
import { register, httptext, iphonehsend, hsmscheck } from "../../api/userapi";
export default {
  name: "register",

  components: {},

  data() {
    // var validatePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入邮箱"));
    //   } else {
    //     const reg = new RegExp(
    //       "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
    //     );
    //     const ismail = reg.test(value);
    //     if (!ismail) {
    //       callback(new Error("邮箱格式不正确！"));
    //     } else {
    //       callback();
    //     }
    //   }
    // };
    var checkData = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          callback(new Error("不能输入汉字!"));
        } else {
          callback();
        }
      }
      callback();
    };
    var checkmobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("负责人手机号不可为空"));
      } else {
        if (value !== "") {
          var reg = /^1[3456789]\d{9}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的手机号码"));
          }
        }
        callback();
      }
    };
    var validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/;
        if (!reg.test(value)) {
          callback(new Error("密码长度要大于6位，由数字和字母组成"));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      title: "服务协议",
      dialogVisible: false,
      checked: true,
      content: "",
      ruleForm: {
        nickname: "",
        email: "",
        password: "",
        password2: "",
        username: "",
        tid: "",
        mobile: "",
        weixin: "",
        code: "",
      },
      timer: null,
      show: true,
      nameForm: { mobile: "", event: "手机号登入", captcha: "" },
      count: 0,
      text: "发送验证码",
      rules: {
        mobile: [
          { validator: checkmobile, trigger: "blur" },
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        nickname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        email: [
          // { validator: validatePass, trigger: "blur" },
          { required: true, message: "请输入邮箱", trigger: "blur" },
        ],
        weixin: [
          { required: true, message: "请输入微信号码", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          { validator: validatePassword, trigger: "blur" },
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        password2: [
          { validator: validatePassword, trigger: "blur" },
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
        username: [
          {
            validator: checkData,
            trigger: "blur",
          },
          { required: true, message: "请输入链接用户名", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    if (window.localStorage.getItem("infortree-shareid") !== undefined) {
      this.ruleForm.tid = window.localStorage.getItem("infortree-shareid");
    }
  },

  methods: {
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.sendcode();
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 发送验证码
    sendcode() {
      var that = this;
      iphonehsend({
        mobile: that.ruleForm.mobile,
        event: "手机号登入",
      }).then((res) => {
        if (res.code === 1) {
          that.$message.success(res.msg);
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    gethttptext(id) {
      httptext({ article_id: id }).then((res) => {
        this.content = res.list.content;
      });
    },

    // 前往登入页
    login() {
      this.$router.push("/login");
    },
    // 打开隐私权限
    opendialog(e) {
      if (e) {
        this.dialogVisible = true;
        this.title = "服务协议";
        this.gethttptext(1);
      } else {
        this.dialogVisible = true;
        this.title = "隐私政策";
        this.gethttptext(2);
      }
    },
    // 关闭对话框
    handleClose(done) {
      done();
    },
    password() {
      if (this.ruleForm.password === this.ruleForm.password2) {
        return true;
      } else {
        return false;
      }
    },
    // 提交注册信息
    submitForm(formName) {
      const that = this;
      var regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im;
      var regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (
        regEn.test(this.ruleForm.username) ||
        regCn.test(this.ruleForm.username)
      ) {
        return this.$message.error("名称不能包含特殊字符");
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!that.checked) {
            this.opendialog(true);
            return that.$message.error("勾选服务协议 和 隐私权政后才能注册");
          }
          if (!that.password()) {
            return that.$message.error("密码必须一致");
          }
          this.nameForm.mobile = this.ruleForm.mobile;
          this.nameForm.captcha = this.ruleForm.code;
          hsmscheck(this.nameForm).then((res) => {
            if (res.code === 1) {
              let data = JSON.stringify(that.ruleForm);
              data = JSON.parse(data);
              delete data.password2;
              delete data.code;
              this.loading = true;
              register(data)
                .then((res) => {
                  if (res.code === 1) {
                    const info = JSON.stringify(res.data.userinfo);
                    window.localStorage.setItem("infortree-userinfo", info);
                    window.localStorage.setItem(
                      "infortree-userid",
                      res.data.userinfo.user_id
                    );
                    setTimeout((res1) => {
                      that.$message.success("注册成功，请修改用户信息");
                      that.$router.push("/accountsetting");
                      that.loading = false;
                    }, 1000);
                  } else {
                    setTimeout((res1) => {
                      that.$message.error(res.msg);
                      that.loading = false;
                    }, 1000);
                  }
                })
                .catch();
            } else {
              this.$message.error("验证码错误");
            }
          });
        } else {
          that.$message.error("提交错误请重新提交");
          that.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tip {
  font-size: 14px;
  color: #999999;
  margin-left: 10px;
}
.login {
  float: right;
}
.btn {
  padding: 20px 0;
}
.box-card {
  width: 500px;
  margin: 30px auto;
  padding: 50px 80px;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
  }
  h3 {
    font-size: 18px;
    color: #333333;
    margin-bottom: 40px;
  }
}
/deep/.el-form-item {
  margin-bottom: 0;
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
.tipurl {
  font-size: 12px;
  color: #333333;
  line-height: 18px;
  padding: 20px 0;
}
.el-checkbox__label {
  font-size: 14px;
  color: #999999;
}
</style>
